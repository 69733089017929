
export const fetchEvents = async () => {
    const options = {
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
    };

    const response = await fetch('/api/events', options);
    if (!response.ok) {
        throw new Error("Oops!");
    }
    return response.json();
};

export const fetchEvent = async (eventId) => {
    const options = {
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
    };

    const response = await fetch(`/api/events/${eventId}`, options);
    if (!response.ok) {
        throw new Error("Oops!");
    }
    const jsonResponse = await response.json();
    return jsonResponse[0];
};

export const getTags = async () => {
    const options = {
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
    };

    const response = await fetch('/api/tags', options);
    if (!response.ok) {
        throw new Error("Oops!");
    }
    return response.json();
};

export const deleteEvent = async (eventId) => {
    const response = await fetch(`/api/events/${eventId}`, {
        method: 'delete',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
    });

    if (!response.ok) {
        throw new Error("Oops!");
    }

    return await response.json();
}

export const uploadPoster = (poster, id, preview=false) => {
    const body = new FormData();
    id && body.append('_id', id);
    poster && body.append("poster", poster);
    return fetch(preview ? '/upload/preview' : './upload', {
        method: 'post',
        body,
    });
}


export const login = async (email, password) => {
    const payload = email && password && { email, password };
    const response = await fetch(`/api/users/login`, {
        method: payload ? 'post' : 'get',
        headers: { 'Content-Type': 'application/json' },
        body: payload && JSON.stringify(payload)
    });

    if (!response.ok) {
        throw new Error("Oops!");
    }

    return await response.json();
}

export const downloadInvoice = async (invoiceData) => {
    return fetch('./api/invoice', {
        method: 'post',
        body: JSON.stringify(invoiceData),
        headers: {'Content-Type': 'application/json'},
    })
}