import React, {useEffect, useState} from 'react';
import { useFetchEvent, useSaveEvent} from "../../services/queries";
import EventForm from "../EventForm/EventForm";
import { useHistory, useParams } from 'react-router-dom';
import { Dimmer, Loader, Segment } from 'semantic-ui-react';


const EditPage = () => {
    const [event, setEvent ] = useState();
    const history = useHistory();

    const { eventId } = useParams();

    const { event: originalEvent, isLoading } = useFetchEvent(eventId);
    
    useEffect(() => {
        setEvent(originalEvent)
    }, [originalEvent]);

    const saveEvent = useSaveEvent();

    const onSave = (event, poster) => {
        saveEvent.mutate({ event, poster });
        history.push("/admin")
    }

    const onCancel = () => (
        history.push("/admin")
    )

    return (
        <>
        <Segment>
            <Dimmer active = {isLoading}>
                <Loader>Loading</Loader>
            </Dimmer>

            <div>
                <EventForm event={event} onSave={onSave} onCancel={onCancel} />
            </div>

      </Segment>
      </>
    )
};


export default EditPage;

EditPage.propTypes = {
};

EditPage.defaultProps = {
};
