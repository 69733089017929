import './App.css';
import React, {useEffect, useState, createContext } from 'react';
import PosterWall from "./components/PosterWall/PosterWall";
import ListingPage from './components/ListingPage/ListingPage';
import { Link, BrowserRouter as Router, Switch, Route, Redirect, useParams } from "react-router-dom";

import { createBrowserHistory } from "history";
import { QueryClient, QueryClientProvider } from "react-query";
import AdminRoute from "./routes/AdminRoute";
import LoginForm from "./components/LoginForm/LoginForm";
import AdminPage from './components/AdminPage/AdminPage';
import EditPage from './components/EditPage/EditPage';

const history = createBrowserHistory();

const queryClient = new QueryClient();

function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <Router history={history}>
                <Switch>
                    <AdminRoute path="/admin/edit/:eventId" >
                        <EditPage />
                    </AdminRoute>
                    <AdminRoute path="/admin/new" >
                        <EditPage />
                    </AdminRoute>
                    <AdminRoute path="/admin">
                        <AdminPage />
                    </AdminRoute>

                    <Route exact path="/">
                        <ListingPage />
                    </Route>
                    <Route exact path="/login">
                        <LoginForm />
                    </Route>
                    <Redirect to="/" />
                </Switch>
            </Router>
        </QueryClientProvider>
    );
}

export default App;
