import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Segment } from "semantic-ui-react";
import InvoiceForm from "../InvoiceForm/InvoiceForm";

const InvoiceModal = ({ isOpen, onClose }) => {

    return (
        <Modal
            onClose={onClose}
            open={isOpen}
        >
            <Modal.Content>
                <Segment basic>
                    <InvoiceForm />
                </Segment>
            </Modal.Content>
        </Modal>
    )
};


export default InvoiceModal;

InvoiceModal.propTypes = {
    name: PropTypes.string,
    editable: PropTypes.bool,
    image: PropTypes.object,
    date: PropTypes.object,
};

InvoiceModal.defaultProps = {
};
