import React, {useState} from 'react'
import {Button, Form, Grid, Header, Message, Segment} from 'semantic-ui-react'
import PropTypes from "prop-types";
import {login} from "../../services/backend";
import { useHistory, useLocation } from "react-router-dom";

const LoginForm = () => {
    const [ email, setEmail ] = useState();
    const [ password, setPassword ] = useState();
    const [ isError, setIsError ] = useState(false);

    const navigate = useHistory();
    const location = useLocation();

    const onLogIn = async (email, password) => {
        try {
            const response = await login(email, password);
            if (response && response.role === "admin") {
                const params = new URLSearchParams(location?.search);
                navigate.push(params.get('redirect') || '/admin');
            }
        } catch {
            setIsError(true);
        }
    }

    return (
        <Grid textAlign='center' style={{height: '100vh'}} verticalAlign='middle'>
        <Grid.Column style={{maxWidth: 450}}>
            <Header as='h2' textAlign='center'>
                Log-in to your account
            </Header>
            <Form error={isError}>
                <Message error icon='times' header="Error logging you in" content='Please check your user name and password.' />
                <Segment>
                    <Form.Input
                        fluid icon='user'
                        iconPosition='left'
                        placeholder='User name'
                        onChange={(update) => setEmail(update.target.value)}
                        />
                    <Form.Input
                        fluid
                        icon='lock'
                        iconPosition='left'
                        placeholder='Password'
                        type='password'
                        onChange={(update) => setPassword(update.target.value)}
                    />

                    <Button
                        fluid
                        onClick={() => onLogIn(email, password)}
                    >
                        Login
                    </Button>
                </Segment>
            </Form>
        </Grid.Column>
    </Grid>
    )
}

export default LoginForm

LoginForm.protoTypes = {
    onLogIn: PropTypes.func.isRequired,
}
