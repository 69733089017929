import React from 'react';
import { Card, Image, Icon, Button } from 'semantic-ui-react';
import './EventCard.css'
import { getPoster, getDate } from '../common';


const EventCard = (event) => {
    return (
        <>
        <Card>
            <Image
                src={getPoster(event)}
                wrapped
                ui={false}
            />
            <Card.Content extra>
                {
                    event.venue && (
                        <>
                            <Icon name='map marker alternate' />{event.venue}
                        </>
                    )
                }
                {
                    event.start && (
                        <>
                            <br />
                            <Icon name='clock outline' />{getDate(event.start, event.end)}
                        </>
                    )
                }
            </Card.Content>
        </Card>
        </>
    )
}

export default EventCard;