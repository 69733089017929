export const CATEGORIES = {
    club: { color: "red", label: "Clubbing" },
    exhibition: { color: "blue", label: "Visiting" },
    concert: { color: "green", label: "Listening" },
    festival: { color: "orange", label: "Gathering" },
    event: { color: "grey", label: "Event"},
}

export const UPLOAD_ICON = (
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAgAAAAIACAMAAADDpiTIAAAAllBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA\n" +
    "AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA\n" +
    "AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA6C80qAAAAMXRSTlMAZt3atqcEoCJE+xop+OeNbeKSVAqB7dUPFdCc\n" +
    "dDEHrJd6WT0d9MvAh/FfxbFLQTVHGVZzMAAACv1JREFUeNrs0jtuwlAUANFH4SARBJLbiC4NiMb731wuQgSMf88V0p05W5gpkiRJkiRJkiRJki\n" +
    "RJkpTCaXNsrtfmuDkV4ezbQ/fv0O6LSH6bXdeza76LMC7nbuB8KYJou1HHIoRtN+GnCGDbTWq+irKL/h4AFv09ACz6ewBY9PcAsOjvAWDR3wPA\n" +
    "or8HgEV/DwCL/h4AFv09ACz6ewBY9PcAsOjvAWDR3wPAor8HgEV/DwCL/h4AFv09ACz6ewBY9PcAsOjvAWDR3wPAor8HgEV/DwCL/h4AFv09AC\n" +
    "z6ewBY9PcAsFt/D+C69/cAqkd/D2B69vcAotf+HsDT7+8BNO/9PYBl2N8DSMb6ewDHeH8PoJjq7wEM0/09gGCuvwfkN9/fA7Jb6u8BuS3394DM\n" +
    "avp7QF51/T0gq9r+HpBTfX8PyGhNfw/IZ11/D8hmbX8PyOXj/T1gjYz9PaBezv4eUCtrfw+ok7e/B9TI3N8DluXu7wFLsvf3gHn5+3vAHEJ/D5\n" +
    "jG6O8BUyj9PWAcp78HjCH194AhVn8PeEfr7wF9vP4e8IrY3wOemP094IHa3wPuuP094Ibc3wNKYff3AHp/D6D39wB6fw+g9/cAen8PoPf3AHp/\n" +
    "D6D39wB6fw+g9/cAen8PoPf3AHp/D6D39wB6fw+g9/cAen8PoPf3AHp/D6D394A/9u12JYEoCqPwZvrRl6XShyYFJZIRFu37v7mgIJKaOT8Sib\n" +
    "3WcwvvQuccR/r+FkDf3wLo+1sAfX8LoO9vAfT9LYC+vwXQ97cA+v4WQN/fAuj7WwB9fwug728B9P0tgL6/BdD3twD6/hZA398C6PtbAH1/C6Dv\n" +
    "jy8Avz+8APdnF+D+7ALcn12A+7MLcH92Ae7PLsD92QW4P7sA92cX4P7sAty/x2EgjFM97gPgKtXrKsp7Sw14ieLm09SA85uo7T5FfgxYnaUGnZ\n" +
    "1GZZ4Amp6isi7V0EVho1TTKOpappqWUddDquk26vIZEP4U6DUw/Dr4NoX+CrhINV1EXatUU+mrQH8KappGZR4DmsZR2V2q4S5KW6QGLaI2zwHg\n" +
    "M4AfAW2zqO44NeA1yvOlMO4LYR/ms1SP2WUAnHob1GO6CoSRBfyqq/wu0JabdeqHdfW/BHz3fJ7acj2h/Df403zymPryOEE8/m07Wo4360W3Ky\n" +
    "e5RyfdrizWm/HyKPRnB7lHB6H/xgDgDADOAOAMAM4A4AwAzgDgDADOAOAMAM4A4AwAzgDgDADOAOAMAM4A4AwAzgDgDADOAOAMAM4A4AwAzgDg\n" +
    "DADOAOAMAM4A4AwAzgDgDADOAOAMAM4A4AwAzgDgDADOAOAMAM4A4AwAzgDgDADOAOAMAM4A4AwAzgDgDADOAOAMAM4A4AwA7p1dOhAAAAAAEO\n" +
    "RvPcjFkABzAswJMCfAnABzAswJMCfAnABzAswJMCfAnABzAswJMCfAnABzAswJMCfAnABzAswJMCfAnABzAswJMCfAnABzAswJMCfAnABzAswJ\n" +
    "MCfAnABzAswJMCfAnABzAswJMCfAnABzAswJMCfAnABzAswJMCfAnABzAswJMCfAnABzAswJMCdA7NwJctpAEAXQZjMCSSxClhCLMItZLHD873\n" +
    "+5VOI4lUpRZSTD8MfT7wYUH9Tqnh7HaQAcpwFwnAbAcRoAx2kAHKcBcJwGwHEaAMdpABynAXCcBsBxGgDHaQAcl8KgiSg2BQwqRLFpwaCWKDaP\n" +
    "MGgpik0HBq1FsckTGJPkouhMYcyTKD5BF4Z0A1GEWjCkLYqRn8CIJBNFaQQjRqJI7WHAXhStKW5uKopYHzcWiaL28owbCgeiyAU73MxOGwA2OB\n" +
    "W4ieIkyg7BrNnDVfWaM08c9jCv7Re7baN+L4WUFfve1fixlFXU76Wx3S32tblcT7w6HnBvb2KVN9xbshjFcg2b/v2/fQBHscoRBA59X75qHIWg\n" +
    "0N2IRbwuKITRWL5k1QOLvlgkAoveSqrLeT4HEPpiDf8ZPNqxVORPwMSiJizTDweY+FKJVweV0JoqYENSN32oe1KBx/P4t+0wfgtkehUSkDVA51\n" +
    "Ws0AGdui8l5VvwSR/EAnkKPtvc6jLGqoWcJRhFUsoJlMK50PtB0gP630lKiMleAP5qxEIuJqydfhvGrIuW32snZwpWj3KxMcX457yaUHsBrcPY\n" +
    "8jLmXZf6XXBN1gKqWEIPQSwhPpnjJSA2lAu9glqDdirks9bOJW81eAK3lHQ3L2PsAP0rYrxvq4rJWAhlXMPTM1K5SAZ6KeFgkGx4flZmyXnGzw\n" +
    "3pNjQC6sr5j45cYgALJGTXNK2p6/8PNY79yqsIqTpCA+b3/7LnKtuwQ4tmLpDz9n+rrLYvYIkJSSEQWFD+vVvIJZqwRTgjOCHysLTj7/+X5jcL\n" +
    "wE/27gVJTSCKwvDBUUZBEUUUx/cDdXR83P1vLkmlktRk1G6VxIY+3xKsH8puboPI7uk3gbqJo1P2BCDetI8nSjb5ufyLGYBIN3TxJG6Yi8VfwQ\n" +
    "MQCeIET9AJ87D3Y0MAIt3Yx3/mxzm7+gsdgIi3czr4b8bRh6Gjn9YG8N37xFniP1g6E4NH5iwO4IfewDmO8c+Mj87A+IflVgfwg1dav8ahM49q\n" +
    "mYnmThi/rku5vO9bFwAxAGIAxACIARADIAZADIAYADEA2zEAyzEAyzEAyzEAyzEAyzEAyzEAyzEAyzEAyzEAyzEAyzEAyzEAyzEAyzEAyzEAyz\n" +
    "EAyzEAyzEAyzEAyzEAyzEAyzEAyzEAyzEAyzEAyzGA5/F27TDaR9t4EshDvLfpMNpH81mlxQByY7dN8Ntxmsq9emEfv9U3XQaQB28HfNZve3KP\n" +
    "VoTPkrjBAEznzVx8cSzJ7aYdfFFdMQCzdQ84JynLjRpznDMeMACTBSOc51bkJukJF2wYgLneR7jEncgNvD0uajIAU3knXNZ5E30hrigzAENtcE\n" +
    "09zeiDb/0WAzDSyxhXzUTTu4+rIgZgpAjXjVuiZwaFHQMw0EtWH+p+T6CwZwAGGkKl38jqm78tBmCealY/9glKbQZgnBbUhqLB60ApYgDG+YBa\n" +
    "TTT0oLZkAMZpQq2aVUloMADTzKCWiIYBNAQMwDQh1FzR0IaGEgMwzQxqHd4BimuT1X+3CTSkDMA0E6gtRMMb1HyuAozzArVQNKRjKJ0YgHl8KE\n" +
    "1ExwJKGwZgnhAqnTSrHYUXBmCeFVS2oiUYQ2HBp4EmqkGhJ3ocKJQZgIlWLq5yRFOQ4KoDJ4LMFOKafld0tXFNp8cAzNQYZfVLR7hiwKlgU7WW\n" +
    "uGia0QEDDHkuwFw9X7Fy1xXUccHWYwAGK9VxjjuVG3UXOGvGs4FmS+f4armTm3lDfJVMeDrYeOUqPhsPU7nH6oi/bAO+HyAHvMoCf/jD1v0t7V\n" +
    "38ljg9viEkL4LXcD+qjxbbzcqTR3Qnw/2xPjrO43WD7wgiBkAMgBgAMQBiAMQAiAEQA6AHAvgQKqgP6KgIFVQFOgZCBTWFjliooGbQMRcqqDl0\n" +
    "HIUKagQdbipUSKkLLWuhQipDz1CokELo8T2hAvJ8ANwMtlcZuk5CBVSDtpVQ4aygbyFUOAf8xAdCdnrFLfqBUKEEfdzkwKVgoXg1/MLdIBsNcb\n" +
    "OmUGG08RkHQ+wywN84GWKTNu4UNoRyr+HgbseSUM6VRnhAJ+ZNINe8doLH1CvcEcgtr1LH46pNbgvmUqtZRUYO8ZqDgrmSruMFsuUvoq1DObCN\n" +
    "Fj7oW3twQAIAAAAg6P/rdgQqAAAAAAAAAAAAAAAAAAAAAAC8BbGPjfZfVwIvAAAAAElFTkSuQmCC"
);


export const getPoster = (event) => {
    return event?.poster?.blob
    || (event?.poster && `/images/${event._id}.jpeg?${event.updated}`)
    || undefined
};

export const getDate = (startDate, endDate) => {
    const eventDate = new Date(startDate);
    const now = new Date();

    const timeDiff = eventDate.getTime() - now.getTime();
    const diffDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

    const hours = eventDate.getHours();
    const minutes = eventDate.getMinutes();
  
    let day;
    if (diffDays === 0) {
        if (hours > 6) day = "Tonight";
        day = "Today"
    } else if (diffDays === 1) {
        if (hours > 6) day = "Tomorrow";
        day = "Tomorrow";
    } else if (diffDays < 7) {
        day = eventDate.toLocaleDateString('en-GB', { weekday: 'long' });
    } else {
      day = eventDate.toLocaleDateString('en-GB', { weekday: 'long', day: 'numeric', month: 'long' });
    }
    return  `${day} @ ${hours}:${minutes < 10 ? '0' + minutes : minutes}`
  }