import { useMutation, useQuery, useQueryClient} from "react-query";
import {fetchEvents, deleteEvent, uploadPoster, getTags, fetchEvent} from "./backend"

export const useFetchEvents = (filters) => {
    const select = (response) => response.filter(
        event => Object.entries(filters).every(([key, value]) => !value || event[key] === value)
    );
    const options = (filters !== {}) ? { select } : {}
    const { data: events, error, isLoading } = useQuery("events", fetchEvents, options);
    return { events, error, isLoading };
}

export const useFetchEvent = (eventId) => {
    const { data: event, error, isLoading } = useQuery(
        `event-${eventId}`,
        () => fetchEvent(eventId),
        {
            enabled: !!eventId,
        }
    )
    return { event, error, isLoading };
}

export const useSaveEvent = () => {
    const queryClient = useQueryClient()

    return useMutation(async ({event, poster}) => {
        if (poster && event?.poster?.blob) {
            event = {...event, poster:true}
        }
        let response = await fetch('/api/events', {
            method: 'post',
            body: JSON.stringify(event),
            headers: {'Content-Type': 'application/json'},
            credentials: 'include',
        });
        const mutated = await response.json();
        if (event.poster && poster !== undefined) {
            await uploadPoster(poster, mutated._id, false);
        }
        return mutated;
    }, {
        onMutate: async ({ event }) => {
            let optimisticEvent = { _id: Math.random().toString().substring(7), ...event };
            queryClient.setQueryData('events', old => [...(old || []).filter(e => e._id !== optimisticEvent._id), optimisticEvent]);
            return { optimisticEvent } // Return in context
        },
        onError: (err, newTodo, context) => {
            queryClient.setQueryData('events', old => (old || []).filter(event => event._id !== context.optimisticEvent._id))
        },
        onSettled: () => {
            queryClient.invalidateQueries('events');
        },
    });
}

export const useDeleteEvent = () => {
    const queryClient = useQueryClient();

    return useMutation(
        (event) => {
            return deleteEvent(event._id);
        }, {
            onSuccess: (response, deletedEvent) => {
                queryClient.setQueryData('events', old => (old || []).filter(event => event._id !== deletedEvent._id));
            },
            onSettled: () => {
                queryClient.invalidateQueries('events');
            },
        });
}

export const useFetchTags = () => {
    const { data: tags, error, isLoading } = useQuery("tags", getTags);
    return { tags, error, isLoading };
}


