import PropTypes from "prop-types";
import { useFetchEvents } from "../../services/queries";
import React, {createRef, useState} from "react";
import SearchBar from "../SearchBar/SearchBar";
import EventModal from "../EditPage/EditPage";

import {Card, Grid, Header, Icon, Image, Segment, Sticky} from 'semantic-ui-react'
import { CATEGORIES } from "../common";
import EventWall from "../EventWall/EventWall";


const PosterWall = ({ isAdmin }) => {
    const [ event, setEvent ] = useState();
    const [ searchQuery, setSearchQuery ] = useState({});
    const wallRef = createRef();
    const { events, isLoading } = useFetchEvents(searchQuery);

    return (
        <div ref={wallRef}>
            <Header as='h2' icon textAlign='center'>
                <Grid stackable columns={3}>
                    <Grid.Column>
                        <Icon size={"large"} name='bullhorn' circular />
                        <Header.Content>Best coverage</Header.Content>
                        <Header.Subheader>
                            Everywhere
                        </Header.Subheader>
                    </Grid.Column>
                    <Grid.Column>
                        <Icon size={"large"} name='print' circular />
                        <Header.Content>Print offers</Header.Content>
                        <Header.Subheader>
                            We print your posters
                        </Header.Subheader>
                    </Grid.Column>
                    <Grid.Column>
                        <Icon size={"large"} name='envelope outline' circular />
                        <Header.Content>Contact US</Header.Content>
                        <Header.Subheader>
                            Find out what is happening in Edinburgh
                        </Header.Subheader>
                    </Grid.Column>
                </Grid>
            </Header>

                <Sticky context={wallRef}>
                    <SearchBar isAdmin={isAdmin} onSearchUpdated={setSearchQuery} />
                </Sticky>
                <Segment basic loading={isLoading}>
                <EventWall events={[]} />
                </Segment>
            { event && isAdmin && <EventModal eventId={event?._id && event._id.toString()} isOpen={event} onClose={() => setEvent(null)}/>}
            {/*{ event && <Event eventId={event?._id && event._id.toString()} isOpen={event} onClose={() => setEvent(null)}/>}*/}

        </div>
    )
}


export default PosterWall;

PosterWall.protoTypes = {
    events: PropTypes.array.isRequired,
    canEdit: PropTypes.func,
}


PosterWall.defaultProps = {
    canEdit: false,
};
