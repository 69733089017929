import React, {useEffect, useState, useRef } from 'react';
// import {useFetchEvents, useFetchTags} from "../../services/queries";
import {uploadPoster} from "../../services/backend";

import './EventForm.css'
import 'date-fns';
import PropTypes from "prop-types";
import { Form, TextArea, Grid, Image, Segment, Button, ButtonGroup, Dimmer, Loader } from "semantic-ui-react";
import { CATEGORIES, getPoster, UPLOAD_ICON } from "../common";

const EventForm = ({ event: existingEvent, onSave, onCancel }) => {

    const posterInput = useRef();
    const [poster, setPoster] = useState();
    const [isPosterLoading, setIsPosterLoading] = useState(false);

    useEffect(() => {
        async function loadPreview() {
            setIsPosterLoading(true);
            // Getting image preview from backend
            const res = await uploadPoster(poster, null, true);
            const blob = await res.blob();

            const url = URL.createObjectURL(blob);
            setIsPosterLoading(false);
            setEvent( prevState => ({...prevState, poster: { blob: url } }));
        }
        if (poster) {
            loadPreview();
        }
    }, [poster]);

    useEffect(() => {
        setEvent(existingEvent);
    }, [existingEvent]);


    const [ event, setEvent ] = useState(existingEvent);
    // const { tags, isLoading: tagsLoading } = useFetchTags();
    // const { events, isLoading: eventsLoading } = useFetchEvents();

    const onUpdateField = (e, { name, value }) => {
        switch (name) {
            case "is_series":
                setEvent(prevState => ({ ...prevState,  "is_series": !prevState["is_series"] }));
                break;
            default:
                setEvent(prevState => ({ ...prevState,  [name]: value }));
                break
        }
    }

    const hasPoster = !!event?.poster;

    return (
    <Grid>
        <Grid.Column>
                <Form>
                    <>
                        <input ref={posterInput} name="poster" hidden type="file" accept="image/*,application/pdf" onChange={({target: selected}) => setPoster(selected.files[0])} />
                        
                        <Segment placeholder={!hasPoster} basic={hasPoster} loading={isPosterLoading} onClick={() => posterInput.current.click()}>
                            <Dimmer active={isPosterLoading}>
                                <Loader />
                            </Dimmer>

                            <Image
                                src={(getPoster(event)) || UPLOAD_ICON}
                                label={event?.category && ({
                                    color: CATEGORIES[event?.category]?.color,
                                    content: CATEGORIES[event?.category]?.label,
                                    ribbon: true,
                                })}
                                size='small'
                                centered
                            />
                        </Segment>
                    </>
                    <Form.Group>
                        <Form.Input name="name" label='Event Name' placeholder='Event name' value={event.name} onChange={onUpdateField} />
                        <Form.Checkbox
                            toggle
                            name="is_series"
                            label="Save as a series"
                            checked={!!event.is_series}
                            onChange={onUpdateField}
                        />
                    </Form.Group>
                    <TextArea name="description" label='Description' placeholder='Description' rows={6} value={event.description} onChange={onUpdateField} />
                    {
                        !event.is_series && (
                            <>
                                <Form.Group>
                                    <Form.Input clearable="true" name="start" label='Start' type='datetime-local' value={event.start} onChange={onUpdateField} />
                                    <Form.Input clearable="true" name="end"  label='End' type='datetime-local' value={event.end} onChange={onUpdateField} />
                                </Form.Group>
                                <Form.Input name="venue" label='Venue' placeholder='Venue Name' value={event.venue} onChange={onUpdateField} />
                            </>
                        )
                    }
                    {/* <Form.Dropdown
                        name="tags"
                        label='Tags'
                        placeholder='Tags'
                        fluid
                        multiple
                        search
                        selection
                        allowAdditions
                        additionLabel="New tag: "
                        options={([...(tags || []), ...(event.tags || [])]).map(value =>({ text: value, value }))}
                        onChange={onUpdateField}
                        loading={tagsLoading}
                        value={event.tags || []}
                    /> */}
                    <Form.Dropdown
                        name="category"
                        label='Category'
                        placeholder='Category'
                        fluid
                        selection
                        options={Object.keys(CATEGORIES).map(value =>({ text: value.toUpperCase(), value }))}
                        onChange={onUpdateField}
                        value={event.category || ""}
                    />
                    {/* <Form.Dropdown
                        name="parent"
                        label='Parent Event'
                        placeholder='Parent'
                        fluid
                        search
                        selection
                        clearable
                        options={(events || []).filter(event => event.is_series).map(({ name, _id }) =>({ text: name, value: _id }))}
                        onChange={onUpdateField}
                        loading={eventsLoading}
                        value={event.parent}
                    /> */}
                    <ButtonGroup floated="right">
                        <Button onClick={() => onCancel(event, poster)} negative>Cancel</Button>
                        <Button onClick={() => onSave(event, poster)} positive>Save</Button>
                    </ButtonGroup>
                </Form>
        </Grid.Column>
      </Grid>
    );
}

export default EventForm;

EventForm.propTypes = {
    event: PropTypes.object.isRequired,
    onClose: PropTypes.func,
    onSave: PropTypes.func,
};

EventForm.defaultProps = {
    event: {},
};
