import React, {Fragment, useState} from 'react';

import 'date-fns';
import PropTypes from "prop-types";
import {Form, Icon, Table} from "semantic-ui-react";
import {downloadInvoice} from "../../services/backend";

const InvoiceForm = () => {
    const [ invoice, setInvoice ] = useState({});
    const [ item, setItem ] = useState({});

    const onChange = ({ target }) => {
        setInvoice((prevState) => ({...prevState, [target.name]: target.value}));
    }

    const onAddItem = () => {
        setInvoice((prevState) => ({...prevState, total: (prevState.total || 0) + item.cost, items: [...(prevState.items || []), item]}));
        setItem({});
    }

    const onDeleteItem = (id) => {
        setInvoice((prevState) => {
            const items = [...(prevState.items || [])];
            const deleted = items.splice(id, 1)[0]
            setItem(deleted);
            return ({...prevState, total: prevState.total - deleted.cost, items: items})
        });
    }

    const onDownload = async () => {
        try {
            const response = await downloadInvoice(invoice);
            const blob = await response.blob();

            const url = URL.createObjectURL(new Blob([blob]));
            window.open(url, '_blank');
        } catch {
            console.log('error');
        }
    }


    return (
        <>
            <Fragment>
                <Form>
                    <Table compact attached={"top"}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Description</Table.HeaderCell>
                                <Table.HeaderCell>Cost</Table.HeaderCell>
                                <Table.HeaderCell>Action</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {(invoice.items || []).map((item, index) => (
                                <Table.Row key={`item-${index}`}>
                                    <Table.Cell>{item.description}</Table.Cell>
                                    <Table.Cell>{item.cost.toLocaleString()}</Table.Cell>
                                    <Table.Cell collapsing>
                                        <Form.Button
                                        onClick={ () => onDeleteItem(index)}>
                                            Delete
                                        </Form.Button>
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                            <Table.Row>
                                <Table.Cell>
                                    <Form.Input value={item.description || ""} onChange={({target}) => setItem(prevState => ({...prevState, description: target.value}))} />
                                </Table.Cell>
                                <Table.Cell>
                                    <Form.Input value={item.cost || ""}  onChange={({target}) => setItem(prevState => ({...prevState, cost: Number(target.value)}))} />
                                </Table.Cell>
                                <Table.Cell collapsing>
                                    <Form.Button onClick={onAddItem}>
                                        Add
                                    </Form.Button>
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>

                    <Table attached definition>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>
                                    For the Attention Of
                                </Table.Cell>
                                <Table.Cell>
                                    <Form.Input name="name" placeholder='Client name' value={invoice.name || ""} onChange={onChange} />
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    Total
                                </Table.Cell>
                                <Table.Cell>
                                    {(invoice.total || 0).toLocaleString()}
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>



                        <Table.Footer fullWidth>
                            <Table.Row>
                                <Table.HeaderCell />
                                <Table.HeaderCell colSpan='3'>
                                    <Form.Button
                                        floated='right'
                                        icon
                                        labelPosition='left'
                                        primary
                                        size='small'
                                        onClick={() => onDownload(invoice)}
                                    >
                                        <Icon name='paper plane outline' /> Generate
                                    </Form.Button>
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>

                    </Table>
                </Form>
            </Fragment>
        </>
    );
}

export default InvoiceForm;

InvoiceForm.propTypes = {
    event: PropTypes.object.isRequired,
    onClose: PropTypes.func,
    onSave: PropTypes.func,
};

InvoiceForm.defaultProps = {
    event: {},
};
