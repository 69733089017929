import React from 'react';
import { Card } from 'semantic-ui-react';
import EventCard from '../EventCard/EventCard';

const EventWall = ({events}) => {
    return (
        <Card.Group centered>
            {events && events.map(
                (event) => (
                    <EventCard key={event._id} {...event} />
                ))}
        </Card.Group>
    )
}

export default EventWall;