import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import { Form, Input, Menu } from "semantic-ui-react";
import {CATEGORIES} from "../common";
import InvoiceModal from "../InvoiceModal/InvoiceModal";
import EventModal from "../EditPage/EditPage";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";


const SearchBar = ({ onSearchUpdated, isAdmin }) => {
    const [search, setSearch] = useState({});
    const [modal, setModal] = useState();
    const history = useHistory();

    const onUpdateField = (e, { name, value }) => {
        switch (name) {
            default:
                setSearch(prevState => ({ ...prevState,  [name]: value || undefined }));
                break
        }
    }

    // useEffect(() => search != {} && onSearchUpdated(search), [search, onSearchUpdated]);
    const handleNewEvent = () => history.push('/admin/new');

    return (
        <Menu>
            {
                isAdmin && (
                    <Menu.Item>
                        <Form.Button onClick={() => handleNewEvent()}>New Event</Form.Button>
                    </Menu.Item>
                    )
            }
{/* 
            <Menu.Item>
                <Input icon='search' placeholder='Search...'/>
            </Menu.Item>

            <Menu.Item>
                <Form.Dropdown
                    name="category"
                    placeholder='Category'
                    selection 
                    options={Object.entries(CATEGORIES).map(([value, { label }]) =>({ text: label, value }))}
                    onChange={onUpdateField}
                    value={search.category || ""}
                    clearable
                />
            </Menu.Item> */}
        </Menu>
    )
}

//     return (
//         <>
//             <Menu>


//                 <Menu.Item position='right'>
//                     <Form.Checkbox
//                         toggle
//                         name="is_series"
//                         label="View series"
//                         checked={!!search.is_series}
//                         onChange={onUpdateField}
//                     />
//                 </Menu.Item>
//             </Menu>
//             { modal === "invoice" && <InvoiceModal isOpen={true} onClose={() => setModal(null)} />}
//             { modal === "new" && <EventModal isOpen={true} onClose={() => setModal(null)}/>}
//         </>
//     )
// };

// //         <TextField
// //             label="Date"
// //             name="date"
// //             type="datetime-local"
// //             InputLabelProps={{
// //                 shrink: true,
// //             }}
// //             defaultValue=""
// //             onChange={onUpdateField}
// //         />
// //         <TextField label="Category" name="category" select defaultValue="" onChange={onUpdateField}>
// //             <MenuItem value="event">Event</MenuItem>
// //             <MenuItem value="club">Club</MenuItem>
// //             <MenuItem value="concert">Concert</MenuItem>
// //             <MenuItem value="exhibition">Exhibition</MenuItem>
// //             <MenuItem value="theatre">Theatre</MenuItem>
// //             <MenuItem value="festival">Festival</MenuItem>
// //         </TextField>
// //         <TextField label="Venue" name="location" select defaultValue="" onChange={onUpdateField}>
// //             <MenuItem value="paradiso">Paradiso</MenuItem>
// //             <MenuItem value="club">Aerodrome</MenuItem>
// //             <MenuItem value="concert">Coliseum</MenuItem>
// //             <MenuItem value="exhibition">Somewhere</MenuItem>
// //             <MenuItem value="theatre">The Forum</MenuItem>
// //             <MenuItem value="festival">Apollo Theatre</MenuItem>
// //         </TextField>
// //         <Button disabled={!isModified} type={"submit"} onClick={onSearch}>Search</Button>
// //     </Toolbar>
// // </div>
// // )

export default SearchBar;

SearchBar.propTypes = {
    canEdit: PropTypes.bool,
    onEdit: PropTypes.func,
    onSearch: PropTypes.func,
}

SearchBar.defaultProps = {
    canEdit: false,
};
